<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div id="data-list-list-view" class="data-list-container">
      <h1>{{ $t("patients") }}</h1>
      <br>  
      <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="patients">
  
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
  
          <div class="flex flex-wrap-reverse items-center data-list-btn-container">
            <div class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
                <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                <span class="ml-2 text-base text-primary">{{ $t("Ajouter") }}</span>
            </div>
          </div>
  
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ patients.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : patients.length }} of {{ queriedItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
  
              <vs-dropdown-item @click="itemsPerPage=20">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=40">
                <span>40</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=60">
                <span>60</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=80">
                <span>80</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
  
        <template slot="thead">
          <vs-th sort-key="Nom_Prenons">{{ $t("Nom_Prenons") }}</vs-th>
          <vs-th sort-key="age">{{ $t("Age") }}</vs-th>
          <vs-th sort-key="gender">{{ $t("gender") }}</vs-th>
          <vs-th sort-key="assured">{{ $t("assured") }}</vs-th>
          <vs-th sort-key="Telephone">{{ $t("Phone") }}</vs-th>
          <vs-th>{{ $t("Action") }}</vs-th> 
        </template>
  
          <template slot-scope="{data}">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.first_name}}{{" "+tr.last_name}}</p>
                </vs-td>
  
                <vs-td >
                  <p class="product-name font-medium truncate">{{ tr.age }}{{ " "+'ans' }}</p>
                </vs-td>
                <vs-td>
                    <p class="product-name font-medium truncate">{{ genders[tr.gender] }}</p>
                </vs-td>
                <vs-td>
              <vs-chip :color="getOrderStatusColor1(tr.insured)" class="product-name font-medium truncate">
                {{assurance_choice[tr.insured]}}
              </vs-chip>
            </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.phoneNumber}}</p>
                </vs-td>

                <vs-td class="whitespace-no-wrap">

                  <div class="flex">
                    <vx-tooltip text="Voir" color="primary">
                     <feather-icon
                      style="color: navy "
                      icon="EyeIcon" 
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      @click="activePrompt2 = true" 
                      v-on:click="openPlayerDialog(tr)"
                      color="warring" 
                      type="border" class="ml-4" />
                   </vx-tooltip>

                     <vx-tooltip :text="$t('Modifier')" color="warning">
                      <feather-icon
                        style="color: #FF9F43"
                        icon="EditIcon"
                        svgClasses="w-5 h-5 hover:text-warning stroke-current"
                        class="ml-2"
                        @click="editData(tr)"
                      />
                    </vx-tooltip>
                    <vx-tooltip :text="$t('Supprimer')" color="danger">
                      <feather-icon
                        @click="deleteData(tr.id)"
                        style="color: red"
                        icon="TrashIcon"
                        svgClasses="w-5 h-5 hover:text-danger stroke-current"
                        class="ml-2"
                      />
                    </vx-tooltip>
  
                  </div>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
      </vs-table>
  
<vs-popup fullscreen class="calendar-event-dialog" :title="this.$t('titre_Ajouter_patient')" :active.sync="activePromptAddEvent">
  
  <div id="user-edit-tab-info">

    <div class="mt-5">

      <form-wizard color="rgba(var(--vs-primary), 1)" errorColor="rgba(var(--vs-danger), 1)" :startIndex="0" :title="null" :subtitle="null" nextButtonText="Suivant" backButtonText="Précédent" finishButtonText="Valider" @on-complete="resetForm2()" ref="wizard2">
        
        <tab-content title="Étape 1" class="mb-5" icon="feather icon-user-check" :before-change="validateStep1">

          <!-- tab 1 content -->
          <form data-vv-scope="step-1">
          <div class="vx-row">
          
            <div class="vx-col md:w-1/2 w-full mt-5">
              <small class="date-label ">{{ $t("Nom") }}</small>
              <vs-input class="w-full mt-2" v-model="last_name"   v-validate="'required'" name="Last_name" />
              <span class="text-danger text-sm " v-show="errors.has('step-1.Last_name')">{{$t("last_name_error")}}</span>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-5">
            <small class="date-label ">{{ $t("Prénom") }}</small>
            <vs-input class="w-full mt-2" v-model="first_name"  v-validate="'required'" name="first_name" />
            <span class="text-danger text-sm " v-show="errors.has('step-1.first_name')">{{$t("first_name_error")}}</span>
            </div>


            <div class="vx-col md:w-1/2 w-full mt-5">
            <small class="date-label ">{{ $t("age") }}</small>
            <vs-input-number
                    :min="0" 
                    :max="120"
                    v-validate="'required'" 
                    name="age"
                    data-vv-validate-on="blur"
                    v-model="age" 
                    class="w-full mt-2"/>
            <span class="text-danger text-sm " v-show="errors.has('step-1.age')">{{$t("age_error")}}</span>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-5">
            <small class="date-label ">{{ $t("gender") }}</small>
            <v-select
                v-validate="'required'"
                name="gender"
                autocomplete
                label="name"
                :reduce="rep => rep.key"
                class="w-full mt-2 " 
                v-model="gender"
                :options="genderscreate">
            </v-select>
            <span class="text-danger text-sm " v-show="errors.has('step-1.gender')">{{$t("gender_error")}}</span>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-5">
              <small class="date-label ">{{ $t("marriageQuote") }}</small>
              <v-select
              v-validate="'required'"
              name="marriageQuote"
              autocomplete
              label="name"
              :reduce="rep => rep.key"
              class="w-full mt-2" 
              v-model="marriageQuote"
              :options="marriageQuotes">
              </v-select>
            <span class="text-danger text-sm " v-show="errors.has('step-1.marriageQuote')">{{$t("marriageQuote_error")}}</span>
            </div>


            <div class="vx-col md:w-1/2 w-full mt-5">
              <small class="date-label">{{ $t("full_name_of_partner") }}</small>
              <vs-input class="w-full mt-2 " v-model="full_name_of_partner"  v-validate="'required'" name="full_name_of_partner" />
              <span class="text-danger text-sm" v-show="errors.has('step-1.full_name_of_partner')">{{$t("full_name_of_partner_error")}}</span>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-5">
              <small class="date-label">{{ $t("city") }}</small>
              <v-select
              v-validate="'required'"
              name="city"
              autocomplete
              label="name"
              :reduce="rep => rep.id"
              class="w-full mt-2" 
              v-model="city"
              :options="cities">
              </v-select>
              <span class="text-danger text-sm" v-show="errors.has('step-1.city')">{{$t("city_error")}}</span>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-5">
              <small class="date-label">{{ $t("address") }}</small>
              <vs-input class="w-full mt-2" v-model="address"   v-validate="'required'" name="address" />
              <span class="text-danger text-sm" v-show="errors.has('step-1.address')">{{$t("Address_error")}}</span>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-5">
            <small class="date-label">{{ $t("occupation") }}</small>
            <v-select
            v-validate="'required'"
            name="occupation"
            autocomplete
            label="name"
            :reduce="rep => rep.id"
            class="w-full mt-2" 
            v-model="occupation"
            :options="occupations">
            </v-select>
            <span class="text-danger text-sm" v-show="errors.has('step-1.occupation')">{{$t("occupation_error")}}</span>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-5">
              <small class="date-label">{{ $t("parentOrTutorFullName") }}</small>
              <vs-input class="w-full mt-2" v-model="parentOrTutorFullName"  v-validate="'required'" name="parentOrTutorFullName" />
              <span class="text-danger text-sm" v-show="errors.has('step-1.parentOrTutorFullName')">{{$t("parentOrTutorFullName_error")}}</span>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-5">
              <small class="date-label">{{ $t("Phone") }}</small>
              <vue-tel-input
              v-model="phoneNumber"
              :enabledCountryCode="true"
              :enabledFlags="true"
              :validCharactersOnly="true"
              data-vv-validate-on="blur"
              v-validate="'required|min:8|max:25'"
              @validate="yourValidationMethod"
              name="phoneNumber"
              class="w-full mt-2">
              </vue-tel-input>
              <span class="text-danger text-sm" v-show="errors.has('step-1.phoneNumber')">{{$t("phoneNumber_error")}}</span>
              
              <div class="w-full mt-2" >
                  <vs-checkbox  v-model="doYouSmoke">
                    {{$t("doYouSmoke")}}
                  </vs-checkbox>
              </div>
                
                <div class="w-full mt-2" >
                  <vs-checkbox  v-model="doYouDrinkAlcohol">
                    {{$t("doYouDrinkAlcohol")}}
                  </vs-checkbox>
                </div> 
                
                <div class="w-full mt-2" >
                  <vs-checkbox  v-model="doYouDrinkDrugs">
                    {{$t("doYouDrinkDrugs")}}
                  </vs-checkbox>
                </div>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-5">
            
            
              <div class="w-full mt-2" >
                <vs-checkbox  v-model="hadAnAllergy">{{$t("hadAnAllergy")}}</vs-checkbox>
              </div>
              <small v-if="hadAnAllergy" class="date-label ">{{ $t("allergie") }}(s)</small>
              <v-select
                  v-if="hadAnAllergy"
                  multiple 
                  :closeOnSelect="false"
                  v-validate="'required'"
                  name="allergy"
                  autocomplete
                  label="name"
                  :reduce="rep => rep.id"
                  class="w-full mt-2" 
                  v-model="allergy"
                  :options="allergies">
                </v-select>
                <p><span v-if="hadAnAllergy" class="text-danger text-sm  " v-show="errors.has('step-1.allergy')">{{$t("allergie_error")}}</span></p>
                
                <div class="w-full mt-2" >
                  <vs-checkbox  v-model="otherAllergyCheck">
                    {{$t("otherAllergy")}}
                  </vs-checkbox>
                </div>
                <small v-if="otherAllergyCheck" class="date-label">{{ $t("otherAllergy") }}</small>
                <vs-input v-if="otherAllergyCheck" class="w-full mt-2 " v-model="otherAllergy"  v-validate="'required'" name="otherAllergy" />
                <p><span v-if="otherAllergyCheck" class="text-danger text-sm" v-show="errors.has('step-1.otherAllergy')">{{$t("otherAllergy_error")}}</span></p>
            
            </div>
          </div>
          </form>
        </tab-content>

        <!-- tab 2 content -->
        <tab-content title="Étape 2" class="mb-5" icon="feather icon-award" :before-change="validateStep2">
          <form data-vv-scope="step-2">
          <div class="vx-row">
              <div class="w-full mt-2" >
                <vs-checkbox  v-model="insured">{{$t("insured")}}</vs-checkbox>
              </div>

            <div class="vx-col md:w-1/2 w-full">
                <small v-if="insured" class="date-label">{{ $t("partner") }}</small>
                <v-select
                    v-if="insured"
                    v-validate="'required'"
                    name="partner"
                    autocomplete
                    label="name"
                    :reduce="rep => rep.id"
                    class="w-full mt-2" 
                    v-model="partner"
                    :options="assurances">
                </v-select>
                <span v-if="insured" class="text-danger text-sm" v-show="errors.has('step-2.partner')">{{$t("partner_error")}}</span>
            </div>

          
            <div class="vx-col md:w-1/2 w-full">
              <small v-if="insured" class="date-label">{{ $t("expirationDate") }}</small>        
              <datepicker :language="fr" 
                v-if="insured"
                v-validate="'required'"
                class="w-full mt-2"
                name="expirationDate"
                v-model="expirationDate" >
              </datepicker>
              <span  v-if="insured" class="text-danger text-sm" v-show="errors.has('step-2.expirationDate')">{{$t("expirationDate_error")}}</span>
            </div>

            <div class="vx-col md:w-1/2 w-full">
              <small v-if="insured" class="date-label">{{ $t("personToContactName") }}</small>
              <vs-input v-if="insured" class="w-full mt-2" v-model="personToContactName"  v-validate="'required'" name="personToContactName" />
              <span v-if="insured" class="text-danger text-sm" v-show="errors.has('step-2.personToContactName')">{{$t("personToContactName_error")}}</span>
            </div>

            <div class="vx-col md:w-1/2 w-full">
              <small v-if="insured" class="date-label">{{ $t("personToContactPhoneNumber") }}</small>
              <vue-tel-input
                v-if="insured"
                v-model="personToContactPhoneNumber" 
                :enabledCountryCode="true"
                :enabledFlags="true"
                :validCharactersOnly="true"
                data-vv-validate-on="blur"
                v-validate="'required|min:8|max:25'"
                @validate="yourValidationMethod"
                name="personToContactPhoneNumber"
                class="w-full mt-2">
              </vue-tel-input>     
              <span v-if="insured" class="text-danger text-sm" v-show="errors.has('step-2.personToContactPhoneNumber')">{{$t("personToContactPhoneNumber_error")}}</span>
            </div>
            
            <div class="vx-col md:w-1/2 w-full">
              <div class="vx-col w-full">
              <div >
                  <small v-if="insured" class="date-label">{{ $t("couverture") }}</small>
                  <v-select
                    v-if="insured"
                    :closeOnSelect="true"
                    name="acte"
                    autocomplete
                    label="name"
                    :reduce="rep => rep"
                    class=" w-full" 
                    v-model="acte"
                    :options="actes">
                  </v-select>
                  <p :hidden="hiddenChamp"><span v-if="insured && rates_values.length === 0"  class="text-danger text-sm" >{{$t("acte_error")}}</span></p>
              </div>

              <div>
                
                <div class="demo-alignment">
                  <small v-if="insured" class="date-label">{{ $t("rate") }}</small>
                    <vx-tooltip text="Tooltip Default">
                      
                        <vs-input-number
                        v-if="insured"
                        :min="0" 
                        :max="100"
                        data-vv-validate-on="blur"
                        name="rate"
                        v-model="rate"
                        class="w-full mb-4 mr-6 "
                        />
                    </vx-tooltip>
                    <vx-tooltip v-if="insured" text="Ajouter">
                      <vs-button :disabled="!(validate_rate)" v-if="insured" @click="submitActe_rate(acte,rate)" class="mb-2 mr-1" color="success" size="small" type="filled" icon="check"></vs-button>
                    </vx-tooltip>
                    <vx-tooltip v-if="insured" text="Modifier/Voir">
                      <vs-button :disabled="!(rates_values.length !== 0)" v-if="insured" @click="edit_actes_rates_obj()" class="mb-2 mr-1" color="warning" size="small" type="filled" icon="edit"></vs-button>
                    </vx-tooltip>
                    <p> <span  :hidden="hiddenChamp" v-if="insured && rates_values.length === 0" class="text-danger text-sm" >{{$t("rate_error")}}</span></p>
                    <div class="demo-alignment">
                        <vs-avatar v-if="insured && acte_rate_table.length !== 0" color="primary" icon-pack="feather" icon="icon-book" />
                        <p> <span  v-if="insured && rates_values.length !== 0" class="text-primary text-sm" > <b>Le patient a{{"  "}}{{rates_values.length}}{{"  "}}acte(s) </b> </span></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          </form>
        </tab-content>

        <!-- tab 3 content -->
        <tab-content title="Étape 3" class="mb-5" icon="feather icon-folder-plus" :before-change="validateStep3">
          <form data-vv-scope="step-3">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <vs-checkbox  v-model="healthyProblemThisYear">
                {{$t("healthyProblemThisYear")}}
              </vs-checkbox>
              <small v-if="healthyProblemThisYear" class="date-label">{{ $t("disease") }}(s)</small>
              <v-select
                  v-if="healthyProblemThisYear"
                  multiple 
                  :closeOnSelect="false"
                  v-validate="'required'"
                  name="disease"
                  autocomplete
                  label="name"
                  :reduce="rep => rep.id"
                  class="w-full mt-2" 
                  v-model="disease"
                  :options="diseases">
              </v-select>
              <span v-if="healthyProblemThisYear" class="text-danger text-sm" v-show="errors.has('step-3.disease')">{{$t("disease_error")}}</span>
            </div>

            <div  class="vx-col md:w-1/2 w-full mt-2">
              <vs-checkbox  v-model="otherDiseaseCheck">
                {{$t("otherDisease")}}
              </vs-checkbox>
              <small v-if="otherDiseaseCheck" class="date-label">{{ $t("otherDisease") }}</small>
              <vs-input v-if="otherDiseaseCheck" class="w-full mt-2" v-model="otherDisease"   v-validate="'required'" name="otherDisease" />
              <span v-if="otherDiseaseCheck" class="text-danger text-sm" v-show="errors.has('step-3.otherDisease')">{{$t("otherDisease_error")}}</span>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-2">
              <vs-checkbox  v-model="treatedInHospitalBefore">
                {{$t("treatedInHospitalBefore")}}
              </vs-checkbox>
              <small v-if="treatedInHospitalBefore" class="date-label">{{ $t("fullNameAndPhoneNumnerOfDoctor") }}</small>
              <vs-input v-if="treatedInHospitalBefore" class="w-full mt-2" v-model="fullNameAndPhoneNumnerOfDoctor"   v-validate="'required'" name="fullNameAndPhoneNumnerOfDoctor" />
              <span v-if="treatedInHospitalBefore" class="text-danger text-sm" v-show="errors.has('step-3.fullNameAndPhoneNumnerOfDoctor')">{{$t("fullNameAndPhoneNumnerOfDoctor_error")}}</span>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-2">
              <vs-checkbox  v-model="previousTreatment_question">
                {{$t("previousTreatment_question")}}
              </vs-checkbox>
              <small v-if="previousTreatment_question" class="date-label">{{ $t("previousTreatment") }}</small>
              <v-select
              v-if="previousTreatment_question"
                  multiple 
                  :closeOnSelect="false"
                  v-validate="'required'"
                  name="previousTreatment"
                  autocomplete
                  label="name"
                  :reduce="rep => rep.id"
                  class="w-full mt-2" 
                  v-model="previousTreatment"
                  :options="previousTreatments">
              </v-select>
            <span v-if="previousTreatment_question" class="text-danger text-sm" v-show="errors.has('step-3.previousTreatment')">{{$t("previousTreatment_error")}}</span>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-2">
                <vs-checkbox  v-model="otherPreviousTreatmentCheck">
                  {{$t("otherPreviousTreatment")}}
                </vs-checkbox>
                <small v-if="otherPreviousTreatmentCheck" class="date-label">{{ $t("otherPreviousTreatment") }}</small>
                <vs-input v-if="otherPreviousTreatmentCheck" class="w-full mt-2" v-model="otherPreviousTreatment"   v-validate="'required'" name="otherPreviousTreatment" />
                <span v-if="otherPreviousTreatmentCheck" class="text-danger text-sm" v-show="errors.has('step-3.otherPreviousTreatment')">{{$t("otherPreviousTreatment_error")}}</span>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-2">
              <vs-checkbox  v-model="takingMedecine">
                {{$t("takingMedecine")}}
              </vs-checkbox>
              <small v-if="takingMedecine" class="date-label">{{ $t("medecine") }}</small>
              <vs-input v-if="takingMedecine" class="w-full mt-2" v-model="medecine"   v-validate="'required'" name="medecine" />
              <p><span v-if="takingMedecine" class="text-danger text-sm" v-show="errors.has('step-3.medecine')">{{$t("medecine_error")}}</span></p>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-2">
              <vs-checkbox  v-model="hadAnOperation">
                  {{$t("hadAnOperation")}}
              </vs-checkbox>
              <small v-if="hadAnOperation" class="date-label">{{ $t("operation") }}(s)</small>
              <v-select
                  v-if="hadAnOperation"
                  multiple 
                  :closeOnSelect="false"
                  v-validate="'required'"
                  name="operation"
                  autocomplete
                  label="name"
                  :reduce="rep => rep.id"
                  class="w-full mt-2" 
                  v-model="operation"
                  :options="operations">
              </v-select>
              <span  v-if="hadAnOperation" class="text-danger text-sm" v-show="errors.has('step-3.operation')">{{$t("operation_error")}}</span>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-2">
              <vs-checkbox  v-model="otherOperationCheck">
                {{$t("otherOperation")}}
              </vs-checkbox>
              <small  v-if="otherOperationCheck" class="date-label">{{ $t("otherOperation") }}</small>
              <vs-input  v-if="otherOperationCheck"  class="w-full mt-2" v-model="otherOperation"  v-validate="'required'" name="otherOperation" />
              <span v-if="otherOperationCheck" class="text-danger text-sm" v-show="errors.has('step-3.otherOperation')">{{$t("otherOperation_error")}}</span>
            </div>

            <div v-if="gender==='female'"  class="vx-col md:w-1/2 w-full mt-2">
              <vs-checkbox  v-model="pregnantOrFeeding">
                {{$t("pregnantOrFeeding")}}
              </vs-checkbox>
              <small  v-if="pregnantOrFeeding && gender==='female'" class="date-label">{{ $t("howmanyWeekOrMonth") }}</small>
              <vs-input  v-if="pregnantOrFeeding && gender==='female'"  class="w-full mt-2" v-model="howmanyWeekOrMonth"  v-validate="'required'" name="howmanyWeekOrMonth" />
              <p><span v-if="pregnantOrFeeding && gender==='female'" class="text-danger text-sm" v-show="errors.has('step-3.howmanyWeekOrMonth')">{{$t("howmanyWeekOrMonth_error")}}</span></p>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-2">
              <small class="date-label">{{ $t("howDidYouKnowUs") }}</small>
              <v-select
                  v-validate="'required'"
                  name="howDidYouKnowUs"
                  autocomplete
                  label="name"
                  :reduce="rep => rep.key"
                  class="w-full mt-2" 
                  v-model="howDidYouKnowUs"
                  :options="howDidYouKnowUses">
                </v-select>
                <span class="text-danger text-sm" v-show="errors.has('step-3.howDidYouKnowUs')">{{$t("howDidYouKnowUs_error")}}</span>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-2">
              <small class="date-label">{{ $t("consultationReason") }}</small>
              <vs-input class="w-full mt-2" v-model="consultationReason"  v-validate="'required'" name="consultationReason" />
              <span class="text-danger text-sm" v-show="errors.has('step-3.consultationReason')">{{$t("consultationReason_error")}}</span>
            </div>

          </div>
          </form>
        </tab-content>


      </form-wizard>

    </div>

    <vs-popup
      class="calendar-event-dialog"
      :title="this.$t('edit_acte')"
      button-cancel = "border"
      :active.sync="activePromptDetailActes">

      <vs-table max-items="3" pagination search :data="acte_rate_table">

      <template slot="thead">
        <vs-th hidden>N°</vs-th>
        <vs-th>Acte(s)</vs-th>
        <vs-th>Taux</vs-th>
        <vs-th>Actions</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
          <vs-td hidden :data="data[indextr].id">
            {{data[indextr].id}}
          </vs-td>
          <vs-td :data="data[indextr].acte">
            {{data[indextr].acte}}
          </vs-td>
          <vs-td :data="data[indextr].rate">
            {{data[indextr].rate}}%
          </vs-td>
          
          <vs-td class="whitespace-no-wrap">
            <div class="flex">
              <vs-button @click.stop="editTopOjd(data[indextr])" color="warning" size="small" type="filled" icon="edit"></vs-button>
              
              <vs-button @click.stop="remove_actes_rates_obj(data[indextr].id)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-popup  
      class="calendar-event-dialog"
      :title="this.$t('update_acte')"
      button-cancel = "border"
      :active.sync="activePromptEditRate_Acte">
      <small v-if="insured" class="date-label">{{ $t("couverture") }}</small>
        <v-select
          :disabled="true"
          v-if="insured"
          :closeOnSelect="true"
          name="acte"
          autocomplete
          label="name"
          :reduce="rep => rep"
          class="w-full" 
          v-model="acte_up"
          :options="actes">
        </v-select>

        <small v-if="insured" class="date-label">{{ $t("rate") }}</small>
        <div class="sm:w-1/0.8 md:w-1/0.8 lg:w-1/0.8 xl:w-1/0.8">
          <vs-input-number
                  v-if="insured"
                  :min="0" 
                  :max="100"
                  data-vv-validate-on="blur"
                  name="rate"
                  v-model="rate_up"
                  class="w-full"
                  />
        </div>
        <div class="flex flex-wrap justify-between my-5">
        
        <vs-button :disabled="!(validate_rate_modification)" @click="update_Objet" color="primary" type="filled">Modifier</vs-button>
        
        <vs-button @click="CancelEvent" color="danger" type="filled">Annuler</vs-button>
      </div>
    </vs-popup>  
    </vs-popup>

</div>

</vs-popup>

  <vs-popup fullscreen class="calendar-event-dialog" :title="this.$t('titre_Modifier_patient')" :active.sync="activePromptAddEvent2">
  
    <div id="user-edit-tab-info">

      <div class="mt-5">

        <form-wizard color="rgba(var(--vs-primary), 1)" errorColor="rgba(var(--vs-danger), 1)" :startIndex="0" :title="null" :subtitle="null" nextButtonText="Suivant" backButtonText="Précédent" finishButtonText="Valider" @on-complete="resetForm()" ref="wizard">
          
          <tab-content title="Étape 1" class="mb-5" icon="feather icon-user-check" :before-change="validateStep1">

            <!-- tab 1 content -->
            <form data-vv-scope="step-1">
            <div class="vx-row">
            
              <div class="vx-col md:w-1/2 w-full mt-5">
                <small class="date-label ">{{ $t("Nom") }}</small>
                <vs-input class="w-full mt-2" v-model="last_name"   v-validate="'required'" name="Last_name" />
                <span class="text-danger text-sm " v-show="errors.has('step-1.Last_name')">{{$t("last_name_error")}}</span>
              </div>

              <div class="vx-col md:w-1/2 w-full mt-5">
              <small class="date-label ">{{ $t("Prénom") }}</small>
              <vs-input class="w-full mt-2" v-model="first_name"  v-validate="'required'" name="first_name" />
              <span class="text-danger text-sm " v-show="errors.has('step-1.first_name')">{{$t("first_name_error")}}</span>
              </div>


              <div class="vx-col md:w-1/2 w-full mt-5">
              <small class="date-label ">{{ $t("age") }}</small>
              <vs-input-number
                      :min="0" 
                      :max="120"
                      v-validate="'required'" 
                      name="age"
                      data-vv-validate-on="blur"
                      v-model="age" 
                      class="w-full mt-2"/>
              <span class="text-danger text-sm " v-show="errors.has('step-1.age')">{{$t("age_error")}}</span>
              </div>

              <div class="vx-col md:w-1/2 w-full mt-5">
              <small class="date-label ">{{ $t("gender") }}</small>
              <v-select
                  v-validate="'required'"
                  name="gender"
                  autocomplete
                  label="name"
                  :reduce="rep => rep.key"
                  class="w-full mt-2 " 
                  v-model="gender"
                  :options="genderscreate">
              </v-select>
              <span class="text-danger text-sm " v-show="errors.has('step-1.gender')">{{$t("gender_error")}}</span>
              </div>

              <div class="vx-col md:w-1/2 w-full mt-5">
                <small class="date-label ">{{ $t("marriageQuote") }}</small>
                <v-select
                v-validate="'required'"
                name="marriageQuote"
                autocomplete
                label="name"
                :reduce="rep => rep.key"
                class="w-full mt-2" 
                v-model="marriageQuote"
                :options="marriageQuotes">
                </v-select>
              <span class="text-danger text-sm " v-show="errors.has('step-1.marriageQuote')">{{$t("marriageQuote_error")}}</span>
              </div>


              <div class="vx-col md:w-1/2 w-full mt-5">
                <small class="date-label">{{ $t("full_name_of_partner") }}</small>
                <vs-input class="w-full mt-2 " v-model="full_name_of_partner"  v-validate="'required'" name="full_name_of_partner" />
                <span class="text-danger text-sm" v-show="errors.has('step-1.full_name_of_partner')">{{$t("full_name_of_partner_error")}}</span>
              </div>

              <div class="vx-col md:w-1/2 w-full mt-5">
                <small class="date-label">{{ $t("city") }}</small>
                <v-select
                v-validate="'required'"
                name="city"
                autocomplete
                label="name"
                :reduce="rep => rep.id"
                class="w-full mt-2" 
                v-model="city"
                :options="cities">
                </v-select>
                <span class="text-danger text-sm" v-show="errors.has('step-1.city')">{{$t("city_error")}}</span>
              </div>

              <div class="vx-col md:w-1/2 w-full mt-5">
                <small class="date-label">{{ $t("address") }}</small>
                <vs-input class="w-full mt-2" v-model="address"   v-validate="'required'" name="address" />
                <span class="text-danger text-sm" v-show="errors.has('step-1.address')">{{$t("Address_error")}}</span>
              </div>

              <div class="vx-col md:w-1/2 w-full mt-5">
              <small class="date-label">{{ $t("occupation") }}</small>
              <v-select
              v-validate="'required'"
              name="occupation"
              autocomplete
              label="name"
              :reduce="rep => rep.id"
              class="w-full mt-2" 
              v-model="occupation"
              :options="occupations">
              </v-select>
              <span class="text-danger text-sm" v-show="errors.has('step-1.occupation')">{{$t("occupation_error")}}</span>
              </div>

              <div class="vx-col md:w-1/2 w-full mt-5">
                <small class="date-label">{{ $t("parentOrTutorFullName") }}</small>
                <vs-input class="w-full mt-2" v-model="parentOrTutorFullName"  v-validate="'required'" name="parentOrTutorFullName" />
                <span class="text-danger text-sm" v-show="errors.has('step-1.parentOrTutorFullName')">{{$t("parentOrTutorFullName_error")}}</span>
              </div>

              <div class="vx-col md:w-1/2 w-full mt-5">
                <small class="date-label">{{ $t("Phone") }}</small>
                <vue-tel-input
                v-model="phoneNumber"
                :enabledCountryCode="true"
                :enabledFlags="true"
                :validCharactersOnly="true"
                data-vv-validate-on="blur"
                v-validate="'required|min:8|max:25'"
                @validate="yourValidationMethod"
                name="phoneNumber"
                class="w-full mt-2">
                </vue-tel-input>
                <span class="text-danger text-sm" v-show="errors.has('step-1.phoneNumber')">{{$t("phoneNumber_error")}}</span>
                
                <div class="w-full mt-2" >
                    <vs-checkbox  v-model="doYouSmoke">
                      {{$t("doYouSmoke")}}
                    </vs-checkbox>
                  </div>
                  
                  <div class="w-full mt-2" >
                    <vs-checkbox  v-model="doYouDrinkAlcohol">
                      {{$t("doYouDrinkAlcohol")}}
                    </vs-checkbox>
                  </div> 
                  
                  <div class="w-full mt-2" >
                    <vs-checkbox  v-model="doYouDrinkDrugs">
                      {{$t("doYouDrinkDrugs")}}
                    </vs-checkbox>
                  </div>
              </div>

              <div class="vx-col md:w-1/2 w-full mt-5">
              
              
                <div class="w-full mt-2" >
                  <vs-checkbox  v-model="hadAnAllergy">{{$t("hadAnAllergy")}}</vs-checkbox>
                </div>
                <small v-if="hadAnAllergy" class="date-label ">{{ $t("allergie") }}(s)</small>
                <v-select
                    v-if="hadAnAllergy"
                    multiple 
                    :closeOnSelect="false"
                    v-validate="'required'"
                    name="allergy"
                    autocomplete
                    label="name"
                    :reduce="rep => rep.id"
                    class="w-full mt-2" 
                    v-model="allergy"
                    :options="allergies">
                  </v-select>
                  <p><span v-if="hadAnAllergy" class="text-danger text-sm  " v-show="errors.has('step-1.allergy')">{{$t("allergie_error")}}</span></p>
                  
                  <div class="w-full mt-2" >
                    <vs-checkbox  v-model="otherAllergyCheck">
                      {{$t("otherAllergy")}}
                    </vs-checkbox>
                  </div>
                  <small v-if="otherAllergyCheck" class="date-label">{{ $t("otherAllergy") }}</small>
                  <vs-input v-if="otherAllergyCheck" class="w-full mt-2 " v-model="otherAllergy"  v-validate="'required'" name="otherAllergy" />
                  <p><span v-if="otherAllergyCheck" class="text-danger text-sm" v-show="errors.has('step-1.otherAllergy')">{{$t("otherAllergy_error")}}</span></p>
              
              </div>
            </div>
            </form>
          </tab-content>

          <!-- tab 2 content -->
          <tab-content title="Étape 2" class="mb-5" icon="feather icon-award" :before-change="validateStep2">
            <form data-vv-scope="step-2">
            <div class="vx-row">
                <div class="w-full mt-2" >
                  <vs-checkbox  v-model="insured">{{$t("insured")}}</vs-checkbox>
                </div>

              <div class="vx-col md:w-1/2 w-full">
                  <small v-if="insured" class="date-label">{{ $t("partner") }}</small>
                  <v-select
                      v-if="insured"
                      v-validate="'required'"
                      name="partner"
                      autocomplete
                      label="name"
                      :reduce="rep => rep.id"
                      class="w-full mt-2" 
                      v-model="partner"
                      :options="assurances">
                  </v-select>
                  <span v-if="insured" class="text-danger text-sm" v-show="errors.has('step-2.partner')">{{$t("partner_error")}}</span>
              </div>

            
              <div class="vx-col md:w-1/2 w-full">
                <small v-if="insured" class="date-label">{{ $t("expirationDate") }}</small>        
                <datepicker :language="fr" 
                  v-if="insured"
                  v-validate="'required'"
                  class="w-full mt-2"
                  name="expirationDate"
                  v-model="expirationDate" >
                </datepicker>
                <span  v-if="insured" class="text-danger text-sm" v-show="errors.has('step-2.expirationDate')">{{$t("expirationDate_error")}}</span>
              </div>

              <div class="vx-col md:w-1/2 w-full">
                <small v-if="insured" class="date-label">{{ $t("personToContactName") }}</small>
                <vs-input v-if="insured" class="w-full mt-2" v-model="personToContactName"  v-validate="'required'" name="personToContactName" />
                <span v-if="insured" class="text-danger text-sm" v-show="errors.has('step-2.personToContactName')">{{$t("personToContactName_error")}}</span>
              </div>

              <div class="vx-col md:w-1/2 w-full">
                <small v-if="insured" class="date-label">{{ $t("personToContactPhoneNumber") }}</small>
                <vue-tel-input
                  v-if="insured"
                  v-model="personToContactPhoneNumber" 
                  :enabledCountryCode="true"
                  :enabledFlags="true"
                  :validCharactersOnly="true"
                  data-vv-validate-on="blur"
                  v-validate="'required|min:8|max:25'"
                  @validate="yourValidationMethod"
                  name="personToContactPhoneNumber"
                  class="w-full mt-2">
                </vue-tel-input>     
                <span v-if="insured" class="text-danger text-sm" v-show="errors.has('step-2.personToContactPhoneNumber')">{{$t("personToContactPhoneNumber_error")}}</span>
              </div>
              
              <div class="vx-col md:w-1/2 w-full">
                <div class="vx-col w-full">
                <div >
                    <small v-if="insured" class="date-label">{{ $t("couverture") }}</small>
                    <v-select
                      v-if="insured"
                      :closeOnSelect="true"
                      name="acte"
                      autocomplete
                      label="name"
                      :reduce="rep => rep"
                      class=" w-full" 
                      v-model="acte"
                      :options="actes">
                    </v-select>
                    <p :hidden="hiddenChamp"><span v-if="insured && rates_values.length === 0"  class="text-danger text-sm" >{{$t("acte_error")}}</span></p>
                </div>

                <div>
                  
                  <div class="demo-alignment">
                    <small v-if="insured" class="date-label">{{ $t("rate") }}</small>
                      <vx-tooltip text="Tooltip Default">
                        
                          <vs-input-number
                          v-if="insured"
                          :min="0" 
                          :max="100"
                          data-vv-validate-on="blur"
                          name="rate"
                          v-model="rate"
                          class="w-full mb-4 mr-6 "
                          />
                      </vx-tooltip>
                      <vx-tooltip v-if="insured" text="Ajouter">
                        <vs-button :disabled="!(validate_rate)" v-if="insured" @click="submitActe_rate(acte,rate)" class="mb-2 mr-1" color="success" size="small" type="filled" icon="check"></vs-button>
                      </vx-tooltip>
                      <vx-tooltip v-if="insured" text="Modifier/Voir">
                        <vs-button :disabled="!(rates_values.length !== 0)" v-if="insured" @click="edit_actes_rates_obj()" class="mb-2 mr-1" color="warning" size="small" type="filled" icon="edit"></vs-button>
                      </vx-tooltip>
                      <p> <span  :hidden="hiddenChamp" v-if="insured && rates_values.length === 0" class="text-danger text-sm" >{{$t("rate_error")}}</span></p>
                      <div class="demo-alignment">
                          <vs-avatar v-if="insured && acte_rate_table.length !== 0" color="primary" icon-pack="feather" icon="icon-book" />
                          <p> <span  v-if="insured && rates_values.length !== 0" class="text-primary text-sm" > <b>Le patient a{{"  "}}{{rates_values.length}}{{"  "}}acte(s) </b> </span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            </form>
          </tab-content>

          <!-- tab 3 content -->
          <tab-content title="Étape 3" class="mb-5" icon="feather icon-folder-plus" :before-change="validateStep3">
            <form data-vv-scope="step-3">
            <div class="vx-row">
              <div class="vx-col md:w-1/2 w-full">
                <vs-checkbox  v-model="healthyProblemThisYear">
                  {{$t("healthyProblemThisYear")}}
                </vs-checkbox>
                <small v-if="healthyProblemThisYear" class="date-label">{{ $t("disease") }}(s)</small>
                <v-select
                    v-if="healthyProblemThisYear"
                    multiple 
                    :closeOnSelect="false"
                    v-validate="'required'"
                    name="disease"
                    autocomplete
                    label="name"
                    :reduce="rep => rep.id"
                    class="w-full mt-2" 
                    v-model="disease"
                    :options="diseases">
                </v-select>
                <span v-if="healthyProblemThisYear" class="text-danger text-sm" v-show="errors.has('step-3.disease')">{{$t("disease_error")}}</span>
              </div>

              <div  class="vx-col md:w-1/2 w-full mt-2">
                <vs-checkbox  v-model="otherDiseaseCheck">
                  {{$t("otherDisease")}}
                </vs-checkbox>
                <small v-if="otherDiseaseCheck" class="date-label">{{ $t("otherDisease") }}</small>
                <vs-input v-if="otherDiseaseCheck" class="w-full mt-2" v-model="otherDisease"   v-validate="'required'" name="otherDisease" />
                <span v-if="otherDiseaseCheck" class="text-danger text-sm" v-show="errors.has('step-3.otherDisease')">{{$t("otherDisease_error")}}</span>
              </div>

              <div class="vx-col md:w-1/2 w-full mt-2">
                <vs-checkbox  v-model="treatedInHospitalBefore">
                  {{$t("treatedInHospitalBefore")}}
                </vs-checkbox>
                <small v-if="treatedInHospitalBefore" class="date-label">{{ $t("fullNameAndPhoneNumnerOfDoctor") }}</small>
                <vs-input v-if="treatedInHospitalBefore" class="w-full mt-2" v-model="fullNameAndPhoneNumnerOfDoctor"   v-validate="'required'" name="fullNameAndPhoneNumnerOfDoctor" />
                <span v-if="treatedInHospitalBefore" class="text-danger text-sm" v-show="errors.has('step-3.fullNameAndPhoneNumnerOfDoctor')">{{$t("fullNameAndPhoneNumnerOfDoctor_error")}}</span>
              </div>

              <div class="vx-col md:w-1/2 w-full mt-2">
                <vs-checkbox  v-model="previousTreatment_question">
                  {{$t("previousTreatment_question")}}
                </vs-checkbox>
                <small v-if="previousTreatment_question" class="date-label">{{ $t("previousTreatment") }}</small>
                <v-select
                v-if="previousTreatment_question"
                    multiple 
                    :closeOnSelect="false"
                    v-validate="'required'"
                    name="previousTreatment"
                    autocomplete
                    label="name"
                    :reduce="rep => rep.id"
                    class="w-full mt-2" 
                    v-model="previousTreatment"
                    :options="previousTreatments">
                </v-select>
              <span v-if="previousTreatment_question" class="text-danger text-sm" v-show="errors.has('step-3.previousTreatment')">{{$t("previousTreatment_error")}}</span>
              </div>

              <div class="vx-col md:w-1/2 w-full mt-2">
                  <vs-checkbox  v-model="otherPreviousTreatmentCheck">
                    {{$t("otherPreviousTreatment")}}
                  </vs-checkbox>
                  <small v-if="otherPreviousTreatmentCheck" class="date-label">{{ $t("otherPreviousTreatment") }}</small>
                  <vs-input v-if="otherPreviousTreatmentCheck" class="w-full mt-2" v-model="otherPreviousTreatment"   v-validate="'required'" name="otherPreviousTreatment" />
                  <span v-if="otherPreviousTreatmentCheck" class="text-danger text-sm" v-show="errors.has('step-3.otherPreviousTreatment')">{{$t("otherPreviousTreatment_error")}}</span>
              </div>

              <div class="vx-col md:w-1/2 w-full mt-2">
                <vs-checkbox  v-model="takingMedecine">
                  {{$t("takingMedecine")}}
                </vs-checkbox>
                <small v-if="takingMedecine" class="date-label">{{ $t("medecine") }}</small>
                <vs-input v-if="takingMedecine" class="w-full mt-2" v-model="medecine"   v-validate="'required'" name="medecine" />
                <p><span v-if="takingMedecine" class="text-danger text-sm" v-show="errors.has('step-3.medecine')">{{$t("medecine_error")}}</span></p>
              </div>

              <div class="vx-col md:w-1/2 w-full mt-2">
                <vs-checkbox  v-model="hadAnOperation">
                    {{$t("hadAnOperation")}}
                </vs-checkbox>
                <small v-if="hadAnOperation" class="date-label">{{ $t("operation") }}(s)</small>
                <v-select
                    v-if="hadAnOperation"
                    multiple 
                    :closeOnSelect="false"
                    v-validate="'required'"
                    name="operation"
                    autocomplete
                    label="name"
                    :reduce="rep => rep.id"
                    class="w-full mt-2" 
                    v-model="operation"
                    :options="operations">
                </v-select>
                <span  v-if="hadAnOperation" class="text-danger text-sm" v-show="errors.has('step-3.operation')">{{$t("operation_error")}}</span>
              </div>

              <div class="vx-col md:w-1/2 w-full mt-2">
                <vs-checkbox  v-model="otherOperationCheck">
                  {{$t("otherOperation")}}
                </vs-checkbox>
                <small  v-if="otherOperationCheck" class="date-label">{{ $t("otherOperation") }}</small>
                <vs-input  v-if="otherOperationCheck"  class="w-full mt-2" v-model="otherOperation"  v-validate="'required'" name="otherOperation" />
                <span v-if="otherOperationCheck" class="text-danger text-sm" v-show="errors.has('step-3.otherOperation')">{{$t("otherOperation_error")}}</span>
              </div>

              <div v-if="gender==='female'"  class="vx-col md:w-1/2 w-full mt-2">
                <vs-checkbox  v-model="pregnantOrFeeding">
                  {{$t("pregnantOrFeeding")}}
                </vs-checkbox>
                <small  v-if="pregnantOrFeeding && gender==='female'" class="date-label">{{ $t("howmanyWeekOrMonth") }}</small>
                <vs-input  v-if="pregnantOrFeeding && gender==='female'"  class="w-full mt-2" v-model="howmanyWeekOrMonth"  v-validate="'required'" name="howmanyWeekOrMonth" />
                <p><span v-if="pregnantOrFeeding && gender==='female'" class="text-danger text-sm" v-show="errors.has('step-3.howmanyWeekOrMonth')">{{$t("howmanyWeekOrMonth_error")}}</span></p>
              </div>

              <div class="vx-col md:w-1/2 w-full mt-2">
                <small class="date-label">{{ $t("howDidYouKnowUs") }}</small>
                <v-select
                    v-validate="'required'"
                    name="howDidYouKnowUs"
                    autocomplete
                    label="name"
                    :reduce="rep => rep.key"
                    class="w-full mt-2" 
                    v-model="howDidYouKnowUs"
                    :options="howDidYouKnowUses">
                  </v-select>
                  <span class="text-danger text-sm" v-show="errors.has('step-3.howDidYouKnowUs')">{{$t("howDidYouKnowUs_error")}}</span>
              </div>

              <div class="vx-col md:w-1/2 w-full mt-2">
                <small class="date-label">{{ $t("consultationReason") }}</small>
                <vs-input class="w-full mt-2" v-model="consultationReason"  v-validate="'required'" name="consultationReason" />
                <span class="text-danger text-sm" v-show="errors.has('step-3.consultationReason')">{{$t("consultationReason_error")}}</span>
              </div>

            </div>
            </form>
          </tab-content>


        </form-wizard>

      </div>

      <vs-popup
        class="calendar-event-dialog"
        :title="this.$t('edit_acte')"
        button-cancel = "border"
        :active.sync="activePromptDetailActes">

        <vs-table max-items="3" pagination search :data="acte_rate_table">

        <template slot="thead">
          <vs-th hidden>N°</vs-th>
          <vs-th>Acte(s)</vs-th>
          <vs-th>Taux</vs-th>
          <vs-th>Actions</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
            <vs-td hidden :data="data[indextr].id">
              {{data[indextr].id}}
            </vs-td>
            <vs-td :data="data[indextr].acte">
              {{data[indextr].acte}}
            </vs-td>
            <vs-td :data="data[indextr].rate">
              {{data[indextr].rate}}%
            </vs-td>
            
            <vs-td class="whitespace-no-wrap">
              <div class="flex">
                <vs-button @click.stop="editTopOjd(data[indextr])" color="warning" size="small" type="filled" icon="edit"></vs-button>
                
                <vs-button @click.stop="remove_actes_rates_obj(data[indextr].id)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-popup  
        class="calendar-event-dialog"
        :title="this.$t('update_acte')"
        button-cancel = "border"
        :active.sync="activePromptEditRate_Acte">
        <small v-if="insured" class="date-label">{{ $t("couverture") }}</small>
          <v-select
            :disabled="true"
            v-if="insured"
            :closeOnSelect="true"
            name="acte"
            autocomplete
            label="name"
            :reduce="rep => rep"
            class="w-full" 
            v-model="acte_up"
            :options="actes">
          </v-select>

          <small v-if="insured" class="date-label">{{ $t("rate") }}</small>
          <div class="sm:w-1/0.8 md:w-1/0.8 lg:w-1/0.8 xl:w-1/0.8">
            <vs-input-number
                    v-if="insured"
                    :min="0" 
                    :max="100"
                    data-vv-validate-on="blur"
                    name="rate"
                    v-model="rate_up"
                    class="w-full"
                    />
          </div>
          <div class="flex flex-wrap justify-between my-5">
          
          <vs-button :disabled="!(validate_rate_modification)" @click="update_Objet" color="primary" type="filled">Modifier</vs-button>
          
          <vs-button @click="CancelEvent" color="danger" type="filled">Annuler</vs-button>
        </div>
      </vs-popup>  
      </vs-popup>

  </div>

  </vs-popup>

  <vs-popup fullscreen class="calendar-event-dialog" :title="this.$t('detail_patient')" :active.sync="activePrompt2">
  <div v-if="selectedPlayer" id="user-edit-tab-info">

    <!-- Permissions -->
    <vx-card class="mt-2" no-shadow card-border>

<div class="vx-row">
  <div class="vx-col w-full">
  <div class="flex items-end px-3">
  <feather-icon svgClasses="w-6 h-6" icon="LockIcon" class="mr-2" />
  <span class="font-medium text-lg leading-none">Quelques questions posées.</span>
</div>
<vs-divider />
</div>
</div>

<div class="block overflow-x-auto">
<table class="w-full">
<tr>
<!--
You can also use `Object.keys(Object.values(data_local.permissions)[0])` this logic if you consider,
our data structure. You just have to loop over above variable to get table headers.
Below we made it simple. So, everyone can understand.
-->
</tr>

<tr >
<td  class="px-3 py-2">{{$t("insured")}}<vs-checkbox disabled v-model="insured"></vs-checkbox></td>
<td  class="px-3 py-2" >{{$t("healthyProblemThisYear")}}<vs-checkbox disabled v-model="healthyProblemThisYear"></vs-checkbox></td>
<td  class="px-3 py-2" >{{$t("hadAnOperation")}}<vs-checkbox disabled v-model="hadAnOperation"></vs-checkbox></td>
<td  class="px-3 py-2" >{{$t("treatedInHospitalBefore")}}<vs-checkbox disabled v-model="treatedInHospitalBefore"></vs-checkbox></td>
<td  class="px-3 py-2" v-if="gender==='female'">{{$t("pregnantOrFeeding")}}<vs-checkbox disabled v-model="pregnantOrFeeding"></vs-checkbox></td>
<td  class="px-3 py-2" >{{$t("otherOperation")}}<vs-checkbox disabled v-model="otherOperationCheck"></vs-checkbox></td>
<td  class="px-3 py-2" >{{$t("otherPreviousTreatment")}}<vs-checkbox disabled v-model="otherPreviousTreatmentCheck"></vs-checkbox></td>
<td  class="px-3 py-2" >{{$t("previousTreatment_question")}}<vs-checkbox disabled v-model="previousTreatment_question"></vs-checkbox></td>
</tr>

<tr >
<td class="px-3 py-2">{{$t("doYouSmoke")}}<vs-checkbox disabled v-model="doYouSmoke"></vs-checkbox></td>
<td class="px-3 py-2" >{{$t("doYouDrinkAlcohol")}}<vs-checkbox disabled v-model="doYouDrinkAlcohol"></vs-checkbox></td>
<td class="px-3 py-2" >{{$t("doYouDrinkDrugs")}}<vs-checkbox disabled v-model="doYouDrinkDrugs"></vs-checkbox></td>
<td class="px-3 py-2" >{{$t("hadAnAllergy")}}<vs-checkbox disabled v-model="hadAnAllergy"></vs-checkbox></td>
<td class="px-3 py-2" >{{$t("takingMedecine")}}<vs-checkbox disabled v-model="takingMedecine"></vs-checkbox></td>
<td class="px-3 py-2" >{{$t("otherAllergy")}}<vs-checkbox disabled v-model="otherAllergyCheck"></vs-checkbox></td>
<td class="px-3 py-2" >{{$t("otherDisease")}}<vs-checkbox disabled v-model="otherDiseaseCheck"></vs-checkbox></td>
</tr>

</table>
</div>
</vx-card>


<div class="vx-row mt-2">
      <div class="vx-col md:w-1/4 w-full">
        
        <small class="date-label ">{{ $t("Nom") }}</small>
        <vs-input readonly class="w-full mt-2 " v-model="last_name"   v-validate="'required'" name="Last_name" />

        
        <small class="date-label ">{{ $t("Prénom") }}</small>
        <vs-input readonly class="w-full mt-2" v-model="first_name"  v-validate="'required'" name="first_name" />

        
        <small class="date-label ">{{ $t("age") }}</small>
        <vs-input-number
                    disabled
                    :min="0" 
                    :max="120"
                    v-validate="'required'" 
                    name="age"
                    data-vv-validate-on="blur"
                    v-model="age" 
                    class="w-full mt-2"
                    />
       
        <small class="date-label ">{{ $t("gender") }}</small>
        <v-select
            disabled
            v-validate="'required'"
            name="gender"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full mt-2 " 
            v-model="gender"
            :options="genderscreate">
          </v-select>


        <small class="date-label ">{{ $t("marriageQuote") }}</small>
        <v-select
            disabled
            v-validate="'required'"
            name="marriageQuote"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full mt-2" 
            v-model="marriageQuote"
            :options="marriageQuotes">
          </v-select>
        
        <small v-if="hadAnAllergy" class="date-label ">{{ $t("allergie") }}(s)</small>
        <v-select
            disabled
            v-if="hadAnAllergy"
            multiple 
            :closeOnSelect="false"
            v-validate="'required'"
            name="allergy"
            autocomplete
            label="name"
            :reduce="rep => rep.id"
            class="w-full mt-2" 
            v-model="allergy"
            :options="allergies">
          </v-select>

         
          <small v-if="otherAllergyCheck" class="date-label">{{ $t("otherAllergy") }}</small>
          <vs-input readonly v-if="otherAllergyCheck" class="w-full mt-2 " v-model="otherAllergy"  v-validate="'required'" name="otherAllergy" />

        </div>

      <div class="vx-col md:w-1/4 w-full">

        <small class="date-label">{{ $t("full_name_of_partner") }}</small>
        <vs-input class="w-full mt-2 " v-model="full_name_of_partner"  v-validate="'required'" name="full_name_of_partner" />

        <small class="date-label">{{ $t("city") }}</small>
        <v-select
            disabled
            v-validate="'required'"
            name="city"
            autocomplete
            label="name"
            :reduce="rep => rep.id"
            class="w-full mt-2" 
            v-model="city"
            :options="cities">
          </v-select>
        
        <small class="date-label">{{ $t("address") }}</small>
        <vs-input readonly class="w-full mt-2" v-model="address"   v-validate="'required'" name="address" />
        
        <small class="date-label">{{ $t("occupation") }}</small>
        <v-select
            disabled
            v-validate="'required'"
            name="occupation"
            autocomplete
            label="name"
            :reduce="rep => rep.id"
            class="w-full mt-2" 
            v-model="occupation"
            :options="occupations">
          </v-select>
        
        <small class="date-label">{{ $t("parentOrTutorFullName") }}</small>
        <vs-input readonly class="w-full mt-2" v-model="parentOrTutorFullName"  v-validate="'required'" name="parentOrTutorFullName" />
        
        <small class="date-label">{{ $t("Phone") }}</small>
        <vue-tel-input
              disabled
              v-model="phoneNumber"
              :enabledCountryCode="true"
              :enabledFlags="true"
              :validCharactersOnly="true"
              data-vv-validate-on="blur"
              v-validate="'required|min:8|max:25'"
              @validate="yourValidationMethod"
              name="phoneNumber"
              class="w-full mt-2"
          >
          </vue-tel-input>
        </div>
      
      <div class="vx-col md:w-1/4 w-full">
        

        <small v-if="insured" class="date-label">{{ $t("partner") }}</small>
        <v-select
            v-if="insured"
            disabled
            v-validate="'required'"
            name="partner"
            autocomplete
            label="name"
            :reduce="rep => rep.id"
            class="w-full mt-2" 
            v-model="partner"
            :options="assurances">
          </v-select>

          <small v-if="insured" class="date-label">{{ $t("couverture") }}(s)</small>
          <v-select
            v-if="insured"
            multiple 
            disabled
            :closeOnSelect="true"
            name="acte"
            autocomplete
            label="name"
            :reduce="rep => rep"
            class=" w-full" 
            v-model="not_actes"
            :options="actes">
          </v-select>
          
        <small v-if="insured" class="date-label">{{ $t("expirationDate") }}</small>        
            <datepicker :language="$vs.rtl ? fr : fr" 
                v-if="insured"
                disabled
                v-validate="'required'"
                class="w-full mt-2"
                name="expirationDate"
                v-model="expirationDate" >
            </datepicker>

        <small v-if="insured" class="date-label">{{ $t("personToContactName") }}</small>
        <vs-input readonly v-if="insured" class="w-full mt-2" v-model="personToContactName"  v-validate="'required'" name="personToContactName" />
        
        <small v-if="insured" class="date-label">{{ $t("personToContactPhoneNumber") }}</small>
        <vue-tel-input
              v-if="insured"
              disabled
              v-model="personToContactPhoneNumber" 
              :enabledCountryCode="true"
              :enabledFlags="true"
              :validCharactersOnly="true"
              data-vv-validate-on="blur"
              v-validate="'required|min:8|max:25'"
              @validate="yourValidationMethod"
              name="personToContactPhoneNumber"
              class="w-full mt-2"
          >
          </vue-tel-input>     
        
        <small v-if="healthyProblemThisYear" class="date-label">{{ $t("disease") }}(s)</small>
        <v-select
            v-if="healthyProblemThisYear"
            multiple 
            disabled
            :closeOnSelect="false"
            v-validate="'required'"
            name="disease"
            autocomplete
            label="name"
            :reduce="rep => rep.id"
            class="w-full mt-2" 
            v-model="disease"
            :options="diseases">
        </v-select>
        
        <small v-if="otherDiseaseCheck" class="date-label">{{ $t("otherDisease") }}</small>
        <vs-input readonly v-if="otherDiseaseCheck" class="w-full mt-2" v-model="otherDisease"   v-validate="'required'" name="otherDisease" />
        
        <small v-if="treatedInHospitalBefore" class="date-label">{{ $t("fullNameAndPhoneNumnerOfDoctor") }}</small>
        <vs-input readonly v-if="treatedInHospitalBefore" class="w-full mt-2" v-model="fullNameAndPhoneNumnerOfDoctor"   v-validate="'required'" name="fullNameAndPhoneNumnerOfDoctor" />
  
        <small v-if="treatedInHospitalBefore" class="date-label">{{ $t("previousTreatment") }}</small>
        <v-select
        v-if="treatedInHospitalBefore"
            disabled
            multiple 
            :closeOnSelect="false"
            v-validate="'required'"
            name="previousTreatment"
            autocomplete
            label="name"
            :reduce="rep => rep.id"
            class="w-full mt-2" 
            v-model="previousTreatment"
            :options="previousTreatments">
         </v-select>

      <small v-if="otherPreviousTreatmentCheck" class="date-label">{{ $t("otherPreviousTreatment") }}</small>
      <vs-input readonly v-if="otherPreviousTreatmentCheck" class="w-full mt-2" v-model="otherPreviousTreatment"   v-validate="'required'" name="otherPreviousTreatment" />
         
      <small v-if="previousTreatment_question" class="date-label">{{ $t("previousTreatment") }}</small>
      <v-select
      v-if="previousTreatment_question"
          multiple 
          disabled
          :closeOnSelect="false"
          v-validate="'required'"
          name="previousTreatment"
          autocomplete
          label="name"
          :reduce="rep => rep.id"
          class="w-full mt-2" 
          v-model="previousTreatment"
          :options="previousTreatments">
      </v-select>

      
      </div>

      <div class="vx-col md:w-1/4 w-full">

      <small v-if="takingMedecine" class="date-label">{{ $t("medecine") }}</small>
      <vs-input readonly v-if="takingMedecine" class="w-full mt-2" v-model="medecine" v-validate="'required'" name="medecine" />
    

        <small v-if="hadAnOperation" class="date-label">{{ $t("operation") }}(s)</small>
        <v-select
            disabled
            v-if="hadAnOperation"
            multiple 
            :closeOnSelect="false"
            v-validate="'required'"
            name="operation"
            autocomplete
            label="name"
            :reduce="rep => rep.id"
            class="w-full mt-2" 
            v-model="operation"
            :options="operations">
         </v-select>
        
        <small  v-if="otherOperationCheck" class="date-label">{{ $t("otherOperation") }}</small>
        <vs-input readonly v-if="otherOperationCheck"  class="w-full mt-2" v-model="otherOperation"  v-validate="'required'" name="otherOperation" />

        <small  v-if="pregnantOrFeeding && gender==='female'" class="date-label">{{ $t("howmanyWeekOrMonth") }}</small>
        <vs-input readonly v-if="pregnantOrFeeding && gender==='female'"  class="w-full mt-2" v-model="howmanyWeekOrMonth"  v-validate="'required'" name="howmanyWeekOrMonth" />


        <small class="date-label">{{ $t("howDidYouKnowUs") }}</small>
        <v-select
            disabled
            v-validate="'required'"
            name="howDidYouKnowUs"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full mt-2" 
            v-model="howDidYouKnowUs"
            :options="howDidYouKnowUses">
          </v-select>
        
        <small class="date-label">{{ $t("consultationReason") }}</small>
        <vs-input readonly class="w-full mt-2" v-model="consultationReason"  v-validate="'required'" name="consultationReason" />

      </div>
     </div>
        
        <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-4 mt-2" type="border" color="warning" @click="initValues">{{$t("Quit")}}</vs-button>
        </div>
      </div>
    </div>

  </div>

  </vs-popup>
  
</div>
    
  </template>
 
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Datepicker from 'vuejs-datepicker'
import {fr} from 'vuejs-datepicker/dist/locale'
export default {
  components:{
    Datepicker,
    FormWizard,
    TabContent
  },
  data () {
    return {
      fr,
      activePromptEditRate_Acte: false,
      activePromptUpdateRate_Acte:false,
      activePromptDetailActes: false,
      activePromptAddEvent: false,
      activePromptAddEvent2: false,
      activePrompt2:false,
      selectedPlayer:undefined,
      assurance_choice: {
        true: 'Assuré(e)',
        false: 'Non Assuré(e)'
      },
      genderChoises: [
        {
          key: 'male',
          name: 'Masculin'
        },
        {
          key: 'female',
          name: 'Féminin'
        }
      
      ],

      howdidyouknowuschoices: [
        {
          key: 'socialNetwork',
          name: 'Réseaux sociaux'
        },
        {
          key: 'other',
          name: 'Autre'
        }
      
      ],

      marriagequotechoices: [
        {
          key: 'single',
          name: 'Célibataire'
        },
        {
          key: 'married',
          name: 'Marié(e)'
        },
        {
          key: 'divorced',
          name: 'Divorcé(e)'
        }
      
      ],
    
      genders: {
        male: 'Masculin',
        female: 'Féminin'
      },
      id:'',
      assurances:[],
      patients: [],
      cites:[],
      occupations:[],
      operations:[],
      partners:[],
      diseases:[],
      previousTreatments:[],
      allergies:[],
      cities:[],
      selected: [],
      genderscreate: [
        {name: 'Masculin', key: 'male'},
        {name: 'Féminin', key: 'female'}
        
      ],
      marriageQuotes: [
        {name: 'Célibataire', key: 'single'},
        {name: 'Marié(e)', key: 'married'},
        {name: 'Divorcé(e)', key: 'divorced'}
        
      ],
      howDidYouKnowUses: [
        {name: 'Réseaux sociaux', key: 'socialNetwork'},
        {name: 'Autres', key: 'other'}
        
      ],
      itemsPerPage: 20,
      isMounted: false,
      addNewDataSidebar: false,
      updated_app : false,

      last_name:'',
      first_name:'',
      age:'',
      gender:'',
      marriageQuote:'',
      full_name_of_partner:'',
      city:'',
      address:'',
      occupation:'',
      parentOrTutorFullName:'',
      insured: false,
      partner:'',
      expirationDate:'',
      personToContactName:'',
      personToContactPhoneNumber:'',
      fullNameAndPhoneNumnerOfDoctor:'',
      howDidYouKnowUs:'',
      consultationReason:'',
      healthyProblemThisYear: false,
      disease:[],
      hadAnOperation: false,
      operation:[],
      treatedInHospitalBefore:false,
      previousTreatment_question:false,
      previousTreatment:[],
      doYouSmoke: false,
      doYouDrinkAlcohol: false,
      doYouDrinkDrugs:false,
      hadAnAllergy: false,

      otherAllergy:'',
      otherDisease :'',
      otherOperation:'',
      otherPreviousTreatment:'',
      

      otherAllergyCheck :false,
      otherDiseaseCheck :false,
      otherOperationCheck:false,
      otherPreviousTreatmentCheck:false,

     
      allergy:[],
      takingMedecine: false,
      medecine:'',
      pregnantOrFeeding: false,
      howmanyWeekOrMonth:'',
      phoneNumber:'',
      code:'',
      input:[],

      diseaseId_old : [],
      allergyId_old : [],
      operationId_old : [],
      previousTreatmentId_old : [],

      allergy_delete:[],
      operation_delete:[],
      previousTreatment_delete:[],
      disease_delete:[],
      
      save_data_allergy: [],
      save_data_operation: [],
      save_data_disease: [],
      save_data_previousTreatment: [],

      actes:[],
      
      rate:'',
      acte:'',

      rate_up:'',
      acte_up:'',
      id_up:'',

      actes_id:[],
      rates_values:[],

      patientHasActeHasRate:[],

      hiddenChamp:true,

      itemIndexActe:-1,
   
      acte_rate_table:[],
      
      items:0,

      not_actes:[],
      not_rates:[]
    }
  },
  
  watch:{
    allergy (value) {
      //console.log('voir le id_allegie-1', value)
      const allergyId_new = []
      const allergyId_new_teste = []
      //allergyId_new.length = 0
      for (let i = 0; i < value.length; i++) {
        allergyId_new.push(value[i])
        this.save_data_allergy = allergyId_new
        //console.log('voir le value de la boucle for', value)
        if (value[i].id) {
          //console.log('voir le id_allegie-2', value[i].id)
          this.save_data_allergy.length = 0
          allergyId_new_teste.push(value[i].id)
          this.save_data_allergy = allergyId_new_teste
        }
        
      }
      /* console.log('voir allergyId_new', allergyId_new)
      console.log('voir save_data_allergy', this.save_data_allergy)
      console.log('voir allergyId_old', this.allergyId_old)
       */

    },

    disease (value) {
      const diseaseId_new = []
      const diseaseId_new_teste = []
      for (let i = 0; i < value.length; i++) {
        //diseaseId_new.length = 0
        diseaseId_new.push(value[i])
        this.save_data_disease = diseaseId_new
        if (value[i].id) {
          this.save_data_disease.length = 0
          diseaseId_new_teste.push(value[i].id)
          this.save_data_disease = diseaseId_new_teste
        }
        
      }
    
    },

    operation (value) {
      const operationId_new = []
      const operationId_new_teste = []
      for (let i = 0; i < value.length; i++) {
        //operationId_new.length = 0
        operationId_new.push(value[i])
        this.save_data_operation = operationId_new
        if (value[i].id) {
          this.save_data_operation.length = 0
          operationId_new_teste.push(value[i].id)
          this.save_data_operation = operationId_new_teste
        }
        
      }
     
    },

    previousTreatment (value) {
      const previousTreatmentId_new = []
      const previousTreatmentId_new_teste = []
      for (let i = 0; i < value.length; i++) {
        //previousTreatmentId_new.length = 0
        previousTreatmentId_new.push(value[i])
        this.save_data_previousTreatment = previousTreatmentId_new
        if (value[i].id) {
          this.save_data_previousTreatment.length = 0
          previousTreatmentId_new_teste.push(value[i].id)
          this.save_data_previousTreatment = previousTreatmentId_new_teste
        }
        
      }

    }


  },
   
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
   
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.patients.length
    },

    validate_rate () {
      if (
        (
          this.acte && this.rate !== '' && this.rate > 0 && this.rate < 100
     
        )) return true
    },

    validate_rate_modification () {
      if (
        (
          this.acte_up !== null && this.rate_up !== '' && this.rate_up > 0 && this.rate_up < 100
     
        )) return true
    },

    validate_rate_update () {
      if (
        (
          this.acte !== null && this.rate !== null
     
        )) return true
    }
   
  },
  methods: {
    resetForm () {
      this.$refs.wizard.reset()
      //this.$refs.form.resetValidation()
    },
    resetForm2 () {
      this.$refs.wizard2.reset()
      //this.$refs.form.resetValidation()
    },
    validateStep1 () {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-1').then(result => {
          if (result) {
            resolve(true)
          } else {
            reject('Vérifier les champs saisis')
          }
        })
      })
    },
    validateStep2 () {
      return new Promise((resolve, reject) => {
        if (this.insured === true) {
          this.$validator.validateAll('step-2').then(result => {
            if (result && this.actes_id.length > 0) {
              resolve(true)
            }  else {
              if (this.actes_id.length === 0) {
                this.hiddenChamp = false
              } else {
                this.hiddenChamp = true
              }
              this.$vs.notify({
                color:'danger',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                time:15000,
                text:'Informantions manquantes,vérifiez les champs saisis!',
                position:'top-center'
              })
              reject('Vérifier les champs saisis')
            }
         
          })
        } else if (this.insured === false) {
          this.$validator.validateAll('step-2').then(result => {
            if (result) {
              resolve(true)
            }  else {
              reject('Vérifier les champs saisis')
            }
         
          })
        }
      })
    },
    validateStep3 () {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-3').then(result => {
          if (result) {
            resolve(true)
            this.submitData()
          } else {
            reject('Vérifier les champs saisis')
          }
        })
      })
    },
    getOrderStatusColor1 (status) {
      if (status === true)   return 'success'
      if (status === false) return 'danger'
    },

    editTopOjd (tr) {
      this.id_up = tr.id
      this.acte_up = tr.acte
      this.rate_up = tr.rate
      this.activePromptEditRate_Acte = true

    },

    updateTop_ojd (tr) {
      this.id_up = tr.id
      this.acte_up = tr.acte
      this.rate_up = tr.rate
      this.activePromptUpdateRate_Acte = true

    },

    CancelEvent () {
      this.activePromptEditRate_Acte = false
      this.activePromptUpdateRate_Acte = false

    },
    update_Objet () {
      const obj = {
        id: this.id_up,
        acte: this.acte_up,
        rate: this.rate_up
      }
      this.UPDATE_ITEM_PRODUIT(this.acte_rate_table, obj)
      this.actes_id.length = 0
      this.rates_values.length = 0
      for (let i = 0; i < this.acte_rate_table.length; i++) {
        this.actes_id.push(this.acte_rate_table[i].id)
        this.rates_values.push(this.acte_rate_table[i].rate)

      }
      this.$vs.notify({
        color:'success',
        icon:'done',
        text:'Modification effectuée avec succès',
        position:'top-right'
      })
      
      this.id_up = ''
      this.activePromptEditRate_Acte = false

    },
    edit_actes_rates_obj () {
      this.activePromptDetailActes = true
    },
    Update_actes_rates_obj () {
      this.activePromptDetailActes = true
    },
    remove_actes_rates_obj (id) {
      this.actes_id.length = 0
      this.rates_values.length = 0
      this.REMOVE_ITEM_ACTE_OBJECT(this.acte_rate_table, id)
      for (let i = 0; i < this.acte_rate_table.length; i++) {
        this.actes_id.push(this.acte_rate_table[i].id)
        this.rates_values.push(this.acte_rate_table[i].rate)

      }
      this.$vs.notify({
        color:'success',
        icon:'delete',
        text:'Information supprimée de la liste',
        position:'top-right'
      })
    },
    
    submit_update_rate (acte, rate) {

      this.actes_id.length = 0
      this.rates_values.length = 0

      const obj = {
        id: acte.id,
        acte: acte.name,
        rate:  parseInt(rate) 
      }
      
      this.FIND_ITEM_ACTE_UP(this.acte_rate_table, obj)
     

      if (this.itemIndexActe === -1) {

        this.ADD_acte_rate_table(obj)
        for (let i = 0; i < this.acte_rate_table.length; i++) {
          this.actes_id.push(this.acte_rate_table[i].id)
          this.rates_values.push(this.acte_rate_table[i].rate)
        }
        this.$vs.notify({
          color:'success',
          icon: 'done',
          time:5000,
          text:'Acte ajouté',
          position:'top-center'
        })
       
        this.rate = ''
        this.acte = ''
      } else if (this.itemIndexActe !== -1) {
        this.$vs.notify({
          color:'warning',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          time:15000,
          text:'L\'acte existe déjà dans la liste',
          position:'top-center'
        })
      }

    },

    submitActe_rate (acte, rate) {
      this.FIND_ITEM_ACTE(this.actes_id, acte.id)

      if (this.itemIndexActe === -1) {
        this.ADD_ITEM_ACTE(this.actes_id, acte.id)
        this.ADD_ITEM_RATE(this.rates_values, rate)
        this.ACTE_RATE_OBJECT(acte, rate)
        this.rate = ''
        this.acte = ''
      } else if (this.itemIndexActe !== -1) {
        this.$vs.notify({
          color:'warning',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          time:15000,
          text:'L\'acte existe déjà dans la liste',
          position:'top-center'
        })
      }
    

    },
    UPDATE_ITEM_PRODUIT (produits, produit) {
      const itemIndex = produits.findIndex((p) => p.id === produit.id)
      Object.assign(produits[itemIndex], produit)
    },
    ADD_acte_rate_table (obj) {
      this.acte_rate_table.unshift(obj)
    },
    
    REMOVE_ITEM_ACTE_OBJECT (produits, produitId) {
      const ItemIndex = produits.findIndex((p) => p.id === produitId)
      produits.splice(ItemIndex, 1)
    },
    ADD_ITEM_ACTE (actes_id, acte) {
      actes_id.unshift(acte)
    },
    ADD_ITEM_RATE (rates_values, rate) {
      rates_values.unshift(rate)
    },
    
    FIND_ITEM_ACTE (actes_id, acte) {
      this.itemIndexActe = actes_id.findIndex((p) => p === acte)
    },
    FIND_ITEM_ACTE_UP (actes_id, acte) {
      this.itemIndexActe = actes_id.findIndex((p) => p.id === acte.id)
    },
   
    ACTE_RATE_OBJECT (acte, rate) {
      const obj = {
        id: acte.id,
        acte:acte.name,
        rate
      }
      this.ADD_ITEM_ACTE(this.acte_rate_table, obj)

    },
    
    openPlayerDialog (player) {
      this.diseaseId_old.length = 0
      this.allergyId_old.length = 0
      this.operationId_old.length = 0
      this.previousTreatmentId_old.length = 0
      this.patientHasActeHasRate.length = 0
      this.actes_id.length = 0
      this.rates_values.length = 0
      this.acte_rate_table = []
      this.not_actes = []
      this.not_rates = []
      this.rate = ''
      this.acte = ''
      //this.patientHasActeHasRate this.actes_id this.rates_values
      if (player.previousTreatment.length === 0) {
        this.previousTreatment_question = false
      } else {
        this.previousTreatment_question = true
      }
      for (let i = 0; i < player.patientHasActeHasRate.length; i++) {
        this.patientHasActeHasRate.push(player.patientHasActeHasRate[i].id)
        this.not_actes.push(player.patientHasActeHasRate[i].acte)
        this.not_rates.push(player.patientHasActeHasRate[i].rate)
      }

      for (let i = 0; i < this.not_actes.length; i++) {
        const obj = {
          id: this.not_actes[i].id,
          acte:this.not_actes[i].name,
          rate:this.not_rates[i]
        }
        this.actes_id.push(this.not_actes[i].id)
        this.rates_values.push(this.not_rates[i])
        this.ADD_ITEM_ACTE(this.acte_rate_table, obj)
      } 

      for (let i = 0; i < player.disease.length; i++) {
        this.diseaseId_old.push(player.disease[i].id)
      }
      for (let i = 0; i < player.allergy.length; i++) {
        this.allergyId_old.push(player.allergy[i].id)
      }
      for (let i = 0; i < player.operation.length; i++) {
        this.operationId_old.push(player.operation[i].id)
      }
      for (let i = 0; i < player.previousTreatment.length; i++) {
        this.previousTreatmentId_old.push(player.previousTreatment[i].id)
      }
      this.selectedPlayer = player
      this.last_name = player.last_name
      this.first_name = player.first_name
      this.age = player.age
      this.gender = player.gender
      this.marriageQuote = player.marriageQuote
      this.full_name_of_partner = player.full_name_of_partner
      this.city = player.city
      this.address = player.address
      this.occupation = player.occupation
      this.parentOrTutorFullName = player.parentOrTutorFullName
      this.insured = player.insured
      this.partner = player.partner
      this.expirationDate = player.expirationDate
      this.personToContactName = player.personToContactName
      this.personToContactPhoneNumber = player.personToContactPhoneNumber
      this.fullNameAndPhoneNumnerOfDoctor = player.fullNameAndPhoneNumnerOfDoctor
      this.howDidYouKnowUs = player.howDidYouKnowUs
      this.consultationReason = player.consultationReason
      this.healthyProblemThisYear = player.healthyProblemThisYear
      this.disease = player.disease
      this.hadAnOperation = player.hadAnOperation
      this.operation = player.operation
      this.treatedInHospitalBefore = player.treatedInHospitalBefore
      this.previousTreatment = player.previousTreatment
      this.doYouSmoke = player.doYouSmoke
      this.doYouDrinkAlcohol = player.doYouDrinkAlcohol
      this.doYouDrinkDrugs = player.doYouDrinkDrugs
      this.hadAnAllergy = player.hadAnAllergy

      this.otherDisease = player.otherMedecine
      this.otherOperation = player.otherOperation
      this.otherPreviousTreatment = player.otherPreviousTreatment
      this.otherAllergy = player.otherAllergy

      this.otherAllergyCheck = this.otherAllergy !== null
      this.otherDiseaseCheck = this.otherDisease !== null
      this.otherOperationCheck = this.otherOperation !== null
      this.otherPreviousTreatmentCheck = this.otherPreviousTreatment !== null
      
      this.allergy = player.allergy
      this.takingMedecine = player.takingMedecine
      this.medecine = player.medecine
      this.pregnantOrFeeding = player.pregnantOrFeeding
      this.howmanyWeekOrMonth = player.howmanyWeekOrMonth
      this.phoneNumber = player.phoneNumber
      this.id = player.id

    },

    /*  patient_validate () {
      if (this.insured === true) {
        this.$validator.validateAll().then(result => {
          if (result && this.actes_id.length > 0) {
            this.submitData()
          } else {
            if (this.actes_id.length === 0) {
              this.hiddenChamp = false
            } else {
              this.hiddenChamp = true
            }
            this.$vs.notify({
              color:'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              time:15000,
              text:'Informantions manquantes,vérifiez les champs saisis!',
              position:'top-center'
            })
          }
        })

      } else if (this.insured === false) {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.submitData()
          }
        })
      
      }
    },
 */
    /*   patient_modificattion () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.submitData()
        }
      })
    }, */

    addNewData () {
      this.initValues()
      this.resetForm2()
      this.activePromptAddEvent = true
    },
    
    editData (date) {
      this.activePromptAddEvent2 = true
      this.updated_app = true
      this.resetForm()
      this.openPlayerDialog(date)
    },
    yourValidationMethod ({ country }) {
      this.code = country.dialCode
    },
    deleteData (id) {
      
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: this.$t('enteteDelete'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`patients/${id}/`)
            .then(response => {
              this.getAllpatients()
              window.getPrendTaCom.success(this.$t('patient_suppression'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('Suppression')})
            })
        }
      })
  
    },
    removeItemAll (arr, value) {
      let i = 0
      while (i < arr.length) {
        if (arr[i] === value) {
          arr.splice(i, 1)
        } else {
          ++i
        }
      }
      return arr
    },
    initValues () {
      this.last_name = ''
      this.first_name = ''
      this.age = ''
      this.gender = ''
      this.marriageQuote = ''
      this.full_name_of_partner = ''
      this.city = ''
      this.address = ''
      this.occupation = ''
      this.parentOrTutorFullName = ''
      this.insured = false
      this.partner = ''
      this.expirationDate = ''
      this.personToContactName = ''
      this.personToContactPhoneNumber = ''
      this.fullNameAndPhoneNumnerOfDoctor = ''
      this.howDidYouKnowUs = ''
      this.consultationReason = ''
      this.healthyProblemThisYear = false
      this.disease = ''
      this.hadAnOperation = false
      this.operation = ''
      this.treatedInHospitalBefore = false
      this.previousTreatment = ''
      this.doYouSmoke = false
      this.doYouDrinkAlcohol = false
      this.doYouDrinkDrugs = false
      this.hadAnAllergy = false
      this.allergy = ''
      this.takingMedecine = false
      this.medecine = ''
      this.pregnantOrFeeding = false
      this.howmanyWeekOrMonth = ''
      this.phoneNumber = ''
      this.code = ''
      this.id = ''

      this.otherDisease = ''
      this.otherOperation = ''
      this.otherPreviousTreatment = ''
      this.otherAllergy = ''

      this.otherAllergyCheck = false
      this.otherDiseaseCheck = false
      this.otherOperationCheck = false
      this.otherPreviousTreatmentCheck = false

      this.activePromptAddEvent2 = false
      this.activePrompt2 = false

      this.hiddenChamp = true
      this.rate = ''
      this.acte = ''

      this.actes_id = []
      this.rates_values = []
      this.patientHasActeHasRate = []
      this.acte_rate_table = []

      this.not_actes = []
      this.not_rates = []

      this.previousTreatment_question = false

    },
    
    async submitData () {
      let date_expirate = ''
      if (this.insured === false) {
        date_expirate = ''
      } else if (this.insured === true) {
        date_expirate = this.$store.state.convertDateTime(new Date(this.expirationDate)).date
      }
      this.$vs.loading()
      if (this.updated_app === false) {
        this.input = {
          last_name:this.last_name,
          first_name:this.first_name,
          age:parseInt(this.age),
          gender:this.gender,
          marriageQuote:this.marriageQuote,
          full_name_of_partner:this.full_name_of_partner,
          city:this.city,
          address:this.address,
          occupation:this.occupation,
          parentOrTutorFullName:this.parentOrTutorFullName,
          insured: this.insured,
          partner:this.partner,
          expirationDate:date_expirate,
          personToContactName:this.personToContactName,
          personToContactPhoneNumber:`+${this.code } ${  this.personToContactPhoneNumber}`,
          fullNameAndPhoneNumnerOfDoctor:this.fullNameAndPhoneNumnerOfDoctor,
          howDidYouKnowUs:this.howDidYouKnowUs,
          consultationReason:this.consultationReason,
          healthyProblemThisYear: this.healthyProblemThisYear,
          disease:this.disease,
          hadAnOperation: this.hadAnOperation,
          operation:this.operation,
          treatedInHospitalBefore:this.treatedInHospitalBefore,
          previousTreatment:this.previousTreatment,
          doYouSmoke: this.doYouSmoke,
          doYouDrinkAlcohol: this.doYouDrinkAlcohol,
          doYouDrinkDrugs:this.doYouDrinkDrugs,
          hadAnAllergy: this.hadAnAllergy,

          otherMedecine :this.otherDisease,
          otherOperation:this.otherOperation,
          otherPreviousTreatment:this.otherPreviousTreatment,
          otherAllergy:this.otherAllergy,

          allergy:this.allergy,
          takingMedecine: this.takingMedecine,
          medecine:this.medecine,
          pregnantOrFeeding: this.pregnantOrFeeding,
          howmanyWeekOrMonth:this.howmanyWeekOrMonth,
          phoneNumber:`+${this.code } ${  this.phoneNumber}`,

          actes:this.actes_id,

          rates:this.rates_values

        }
      } else if (this.updated_app === true) {
        let city_id = ''
        let occupation_id = ''
        let partner_id = ''
        if (this.city.id) {
          city_id = this.city.id
        } else {
          city_id = this.city
        }

        if (this.occupation.id) {
          occupation_id = this.occupation.id
        } else {
          occupation_id = this.occupation
        }
        
        if (this.insured === true) {
          if (this.partner.id) {
            partner_id = this.partner.id
          } else {
            partner_id = this.partner
          }
        }
        

        for (let i = 0; i < this.save_data_operation.length; i++) {
          const supprOp = this.save_data_operation[i]
          this.removeItemAll(this.operationId_old, supprOp)
        }

        for (let i = 0; i < this.save_data_allergy.length; i++) {
          const supprAll = this.save_data_allergy[i]
          this.removeItemAll(this.allergyId_old, supprAll)
        }

        for (let i = 0; i < this.save_data_disease.length; i++) {
          const supprDis = this.save_data_disease[i]
          this.removeItemAll(this.diseaseId_old, supprDis)
        }

        for (let i = 0; i < this.save_data_previousTreatment.length; i++) {
          const supprPre = this.save_data_previousTreatment[i]
          this.removeItemAll(this.previousTreatmentId_old, supprPre)
        }
        this.previousTreatmentId_old =  this.previousTreatmentId_old.filter(element => element !== null)
        this.diseaseId_old =  this.diseaseId_old.filter(element => element !== null)
        this.operationId_old =  this.operationId_old.filter(element => element !== null)
        this.allergyId_old =  this.allergyId_old.filter(element => element !== null)

        //console.log('voir allergyId_old_update', this.allergyId_old)

        this.input = {
          id:this.id,
          last_name:this.last_name,
          first_name:this.first_name,
          age:parseInt(this.age),
          gender:this.gender,
          marriageQuote:this.marriageQuote,
          full_name_of_partner:this.full_name_of_partner,
          city:city_id,
          address:this.address,
          occupation:occupation_id,
          parentOrTutorFullName:this.parentOrTutorFullName,
          insured: this.insured,
          partner:partner_id,
          expirationDate:date_expirate,
          personToContactName:this.personToContactName,
          personToContactPhoneNumber:this.personToContactPhoneNumber,
          fullNameAndPhoneNumnerOfDoctor:this.fullNameAndPhoneNumnerOfDoctor,
          howDidYouKnowUs:this.howDidYouKnowUs,
          consultationReason:this.consultationReason,
          healthyProblemThisYear: this.healthyProblemThisYear,
          disease:this.save_data_disease,
          hadAnOperation: this.hadAnOperation,
          operation:this.save_data_operation,
          treatedInHospitalBefore:this.treatedInHospitalBefore,
          previousTreatment:this.save_data_previousTreatment,
          doYouSmoke: this.doYouSmoke,
          doYouDrinkAlcohol: this.doYouDrinkAlcohol,
          doYouDrinkDrugs:this.doYouDrinkDrugs,
          hadAnAllergy: this.hadAnAllergy,
          
          otherMedecine :this.otherDisease,
          otherOperation:this.otherOperation,
          otherPreviousTreatment:this.otherPreviousTreatment,
          otherAllergy:this.otherAllergy,

          allergy:this.save_data_allergy,
          takingMedecine: this.takingMedecine,
          medecine:this.medecine,
          pregnantOrFeeding: this.pregnantOrFeeding,
          howmanyWeekOrMonth:this.howmanyWeekOrMonth,
          phoneNumber:this.phoneNumber,
          allergy_delete:this.allergyId_old,
          operation_delete:this.operationId_old,
          disease_delete:this.diseaseId_old,
          previousTreatment_delete:this.previousTreatmentId_old,

          patientHasActeHasRate:this.patientHasActeHasRate,

          actes:this.actes_id,

          rates:this.rates_values

        }

      }
      //console.log('this.input', this.input)
      const data = JSON.parse(JSON.stringify(this.input))
      let url = 'patients/'
      let methods = 'post'
      const message = {
        error: this.$t('save_error'),
        success: this.$t('patient_save')
      }
      if (data.id) {
        url += `${data.id}/`
        methods = 'put'
        message.success = this.$t('patient_update')
      }
      this.$http[methods](url, data)
        .then((response) => {
          window.patients.getAllpatients()
          window.getPrendTaCom.success(message.success, response)
          this.initValues()
          this.activePromptAddEvent = false
          this.updated_app = false
          this.$vs.loading.close()
        })
        .catch((error) => {
          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]
            let libelle = ''
            if (item === 'last_name') {
              libelle = this.$t('Nom')
            } else if (item === 'first_name') {
              libelle = this.$t('Prénoms')
            } else if (item === 'age')  {
              libelle = this.$t('age')
            } else if (item === 'phoneNumber')  {
              libelle = this.$t('Phone')
            } else if (item === 'gender') {
              libelle = this.$t('gender')
            } else if (item === 'marriageQuote') {
              libelle = this.$t('marriageQuote')
            } else if (item === 'full_name_of_partner') {
              libelle = this.$t('full_name_of_partner')
            } else if (item === 'address') {
              libelle = this.$t('address')
            } else if (item === 'occupation') {
              libelle = this.$t('occupation')
            } else if (item === 'parentOrTutorFullName') {
              libelle = this.$t('parentOrTutorFullName')
            } else if (item === 'insured') {
              libelle = this.$t('insured')
            } else if (item === 'partner') {
              libelle = this.$t('partner')
            } else if (item === 'expirationDate') {
              libelle = this.$t('expirationDate')
            } else if (item === 'personToContactName') {
              libelle = this.$t('personToContactName')
            } else if (item === 'personToContactPhoneNumber') {
              libelle = this.$t('personToContactPhoneNumber')
            } else if (item === 'fullNameAndPhoneNumnerOfDoctor') {
              libelle = this.$t('fullNameAndPhoneNumnerOfDoctor')
            } else if (item === 'howDidYouKnowUs') {
              libelle = this.$t('howDidYouKnowUs')
            } else if (item === 'consultationReason') {
              libelle = this.$t('consultationReason')
            } else if (item === 'healthyProblemThisYear') {
              libelle = this.$t('healthyProblemThisYear')
            } else if (item === 'disease') {
              libelle = this.$t('disease')
            } else if (item === 'hadAnOperation') {
              libelle = this.$t('hadAnOperation')
            } else if (item === 'operation') {
              libelle = this.$t('operation')
            } else if (item === 'treatedInHospitalBefore') {
              libelle = this.$t('treatedInHospitalBefore')
            } else if (item === 'previousTreatment') {
              libelle = this.$t('previousTreatment')
            } else if (item === 'doYouSmoke') {
              libelle = this.$t('doYouSmoke')
            } else if (item === 'doYouDrinkAlcohol') {
              libelle = this.$t('doYouDrinkAlcohol')
            } else if (item === 'doYouDrinkDrugs') {
              libelle = this.$t('doYouDrinkDrugs')
            } else if (item === 'hadAnAllergy') {
              libelle = this.$t('hadAnAllergy')
            } else if (item === 'allergy') {
              libelle = this.$t('allergy')
            } else if (item === 'takingMedecine') {
              libelle = this.$t('takingMedecine')
            } else if (item === 'medecine') {
              libelle = this.$t('medecine')
            } else if (item === 'pregnantOrFeeding') {
              libelle = this.$t('pregnantOrFeeding')
            } else if (item === 'howmanyWeekOrMonth') {
              libelle = this.$t('howmanyWeekOrMonth')
            } else if (item === 'otherPreviousTreatment') {
              libelle = this.$t('otherPreviousTreatment')
            } else if (item === 'otherOperation') {
              libelle = this.$t('otherOperation')
            } else if (item === 'otherDisease') {
              libelle = this.$t('otherDisease')
            } else if (item === 'otherAllergy') {
              libelle = this.$t('otherAllergy')
            } else if (item === 'otherMedecine') {
              libelle = this.$t('otherMedecine')
            } else if (item === 'city') {
              libelle = this.$t('city')
            } 
            for (let j = 0; j < error.response.data[item].length; j++) {
              window.getPrendTaCom.error(`${libelle} :  ${error.response.data[item][j]}`)
            }
          }

          window.getPrendTaCom.error(message.error)
        }) 
    },
    getAllpatients () {
      this.$vs.loading()
      this.$http.get('patients/')
        .then((response) => {
          this.patients = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  },
  created () {
    this.$vs.loading()
    window.patients = this
    this.getAllpatients()
    document.title = 'MooziMed'
    this.$http.get('cities/')
      .then((response) => {
        this.cities = response.data
        this.$vs.loading.close()
      })
      .catch(() => {
        this.$vs.loading.close()
      })
    this.$http.get('occupations/')
      .then((response) => {
        this.occupations = response.data
        this.$vs.loading.close()
      })
      .catch(() => {
        this.$vs.loading.close()
      })
    this.$http.get('allergies/')
      .then((response) => {
        this.allergies = response.data
        this.$vs.loading.close()
      })
      .catch(() => {
        this.$vs.loading.close()
      })
    this.$http.get('operations/')
      .then((response) => {
        this.operations = response.data
        this.$vs.loading.close()
      })
      .catch(() => {
        this.$vs.loading.close()
      })
    this.$http.get('diseases/')
      .then((response) => {
        this.diseases = response.data
        this.$vs.loading.close()
      })
      .catch(() => {
        this.$vs.loading.close()
      })
    this.$http.get('partners/')
      .then((response) => {
        this.partners = response.data
        this.$vs.loading.close()
      })
      .catch(() => {
        this.$vs.loading.close()
      })
    this.$http.get('previousTreatments/')
      .then((response) => {
        this.previousTreatments = response.data
        this.$vs.loading.close()
      })
      .catch(() => {
        this.$vs.loading.close()
      })

    this.$http.get('assurances/')
      .then((response) => {
        this.assurances = response.data
        this.$vs.loading.close()
      })
      .catch(() => {
        this.$vs.loading.close()
      })

    this.$http.get('actes/')
      .then((response) => {
        this.actes = response.data
        this.$vs.loading.close()
      })
      .catch(() => {
        this.$vs.loading.close()
      })
  },
  mounted () {
    this.isMounted = true
  }
}
</script>
  
  <style lang="scss">
  #data-list-list-view {
    .vs-con-table {
  
      /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;
  
          .vs-table--search-input {
            width: 100%;
          }
        }
      }
  
      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }
  
      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;
  
          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }
  
      .product-name {
        max-width: 23rem;
      }
  
      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }
  
        .vs-table--search{
          padding-top: 0;
  
          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;
  
            &+i {
              left: 1rem;
            }
  
            &:focus+i {
              left: 1rem;
            }
          }
        }
      }
  
      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;
  
        tr{
            box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
            td{
              padding: 20px;
              &:first-child{
                border-top-left-radius: .5rem;
                border-bottom-left-radius: .5rem;
              }
              &:last-child{
                border-top-right-radius: .5rem;
                border-bottom-right-radius: .5rem;
              }
            }
            td.td-check{
              padding: 20px !important;
            }
        }
      }
  
      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;
  
          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }
  
      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>
  